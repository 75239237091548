import React from 'react'; //eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'; //eslint-disable-line no-unused-vars

import {MainTopbar} from './main-topbar';

class MainLayout extends React.Component {

    static propTypes = {
        children: PropTypes.any,
    };

    render() {
        const props = this.props;
        return (
            <div>
                <MainTopbar title="Documents" />

                <main className="main-content px-8">
                    {props.children}
                </main>
            </div>
        );
    }
    
    shouldComponentUpdate() {
        return true; // Layout component, has children
    }
}

export { MainLayout };
