import {CSSProperties, useMemo} from 'react';

import logoSimpleProd from '@/assets/images/logo-small.svg';

type AppLogoSmallProps = {
    className?: string;
    height?: number;
};

function AppLogoSmall(props: AppLogoSmallProps) {
    const height = props.height ?? 50;
    const style: CSSProperties = useMemo(() => {
        return {
            display: 'inline-block',
            position: 'relative',
            height: height,
        };
    }, [height]);
    const imgStyle: CSSProperties = useMemo(() => {
        return {
            position: 'relative',
            height: height,
        };
    }, [height]);

    return (
        <div className={props.className} style={style}>
            <img src={logoSimpleProd as string} alt="Gabi SmartCare" height={height} style={imgStyle}/>
            {GABI_ENV === 'acceptance' && (
                <EnvLetter letter="A" color="#08997D" height={height}/>
            )}
            {GABI_ENV === 'development' && (
                <EnvLetter letter="D" color="#BF1FBF" height={height}/>
            )}
            {GABI_ENV === 'testing' && (
                <EnvLetter letter="T" color="#BF1FBF" height={height}/>
            )}
            {GABI_ENV === 'preprod' && (
                <EnvLetter letter="P" color="#EF852E" height={height}/>
            )}
        </div>
    );
}

function EnvLetter({color, letter, height}: {color: string, letter: string, height: number}) {
    height = height ?? 50;
    const style: CSSProperties = useMemo(() => ({
        position: 'absolute',
        fontSize: height/50*16,
        fontWeight: 700,
        borderRadius: height/50*15,
        left: height/50*37,
        top: height/50*-9,
        width: height/50*20,
        height: height/50*20,
        lineHeight: `${height/50*20.5}px`,
        textAlign: 'center',
        backgroundColor: color,
    }), [height, color]);

    return (
        <div className="logo-full__env-letter" style={style}>
            {letter}
        </div>
    );
}

export { AppLogoSmall };
