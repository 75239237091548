import { applyMiddleware, createStore, combineReducers } from 'redux';
import ReduxThunk from 'redux-thunk';
import { v4 as uuid } from 'uuid';

const createStoreWithMiddleware = applyMiddleware(ReduxThunk)(createStore);

export function init(models, extraReducers = {}) {
    if (models && typeof models === 'object' && !Array.isArray(models)) {
        const reducers = extractReducers(models, extraReducers);
        return createStoreWithMiddleware(reducers, globalThis.__PRELOADED_STATE__, globalThis.__REDUX_DEVTOOLS_EXTENSION__ && globalThis.__REDUX_DEVTOOLS_EXTENSION__());
    }
    else {
        throw new Error('models must be an object');
    }
}

/**
 * @example
 * model({
 *     name: 'counter',
 *     state: 1,
 *     reducers: {
 *         add: (state, data) => {
 *             return state+data;
 *         },
 *         decr: (state, data) => {
 *             return state-1;
 *         },
 *         incr: (state, data) => {
 *             return state+1;
 *         },
 *     },
 *     actions: {
 *         sub: (val) => {
 *             return counter.actions.add(-val);
 *         },
 *     }
 * });
 * @param params Object :
 * {
 *   name
 *   state
 *   reducers: {
 *       fn_name: (state, data) => { return state }
 *   },
 *   actions: {
 *       fn_name: (data) => { return state }
 *   }
 * }
 */
export function model(params) {
    checkModelDefinition(params);
    const actions = buildActions(params.name, params.reducers, params.actions);
    return Object.assign({}, params, {
        actions,
    });
}

//TODO asyncModel must return a promise. It should be good to support callbacks & async/await as well
export function asyncModel(params) {
    checkModelDefinition(params);
    const asyncParams = Object.assign({}, params);
    const initialState = {
        data: params.state,
        meta: {
            status: AsyncStatus.INIT,
            pendingActions: [],
        },
    };
    asyncParams.state = initialState;
    
    const predefinedReducers = {
        //FIXME collision risk if the user defines a set reducer. Warning ?
        set: (state, data, error, meta) => {
            //console.log('Setting', data);
            const newMeta = Object.assign({}, state.meta, meta);
            return {
                data: data,
                error: error,
                meta: newMeta,
            };
        },
    
        setMeta: (state, newMeta) => {
            const newState = Object.assign({}, state);
            newState.meta = Object.assign({}, state.meta, newMeta);
            return newState;
        },
    
        addPendingAction: (state, action) => {
            //TODO it should be better to define celarly what gets in the pending actions. Right now it's a bit confused
            const newState = Object.assign({}, state);
            const newMeta = Object.assign({}, newState.meta);
            
            action = Object.assign({}, action);
            if (!action.id) {
                action.id = uuid();
            }
        
            const newActions = (newMeta.pendingActions || []).slice();
            newActions.push(action);
            newMeta.pendingActions = newActions;
            newState.meta = newMeta;
            
            //console.log('addPendingAction', action, state, newState);
        
            return newState;
        },
    
        updateMeta: (state, extraMeta) => {
            const newState = Object.assign({}, state);
            const newMeta = Object.assign({}, state.meta, extraMeta);
            newState.meta = newMeta;
            return newState;
        },
    
        removePendingAction: (state, action) => {
            //TODO it should be better to define celarly what gets in the pending actions. Right now it's a bit confused
            const newState = Object.assign({}, state);
            const newMeta = Object.assign({}, newState.meta);
            
            const pendingActions = newMeta.pendingActions || [];
        
            newMeta.pendingActions = pendingActions.filter(item => {
                return item.id !== action.id;
            });
            newState.meta = newMeta;
        
            return newState;
        },
        
        reset: () => {
            return initialState;
        },
    };
    const predefinedActions = buildActions(params.name, predefinedReducers, {});
    const actions = buildActions(params.name, params.reducers, params.actions);
    
    asyncParams.reducers = Object.assign(predefinedReducers, asyncParams.reducers);
    asyncParams.actions = Object.assign(predefinedActions, asyncParams.actions, actions);
    
    return asyncParams;
}

export const AsyncStatus = Object.freeze({
    INIT: 'INIT',
    FETCHING: 'FETCHING',
    FETCHED_PART: 'FETCHED_PART',
    FETCHED: 'FETCHED',
    ERROR: 'ERROR',
});
export const AsyncAction = Object.freeze({
    POST: 'POST',
    PUT: 'PUT',
});
//----------------------------------------------------------------------------------------------------------------------

function checkModelDefinition(params) {
    if (!params.name || typeof params.state === 'undefined' || !params.reducers) {
        throw new Error('model must have a name, an initial state and reducers');
    }
}

function extractReducers(models, extraReducers) {
    const out = {};
    Object.keys(models)
        .forEach(k => {
            const model = models[k];
            if (model.reducers) {
                out[k] = buildReducer(model);
            }
            else {
                console.warn(`No reducer found for model '${k}'`);
            }
        });
    
    //TODO check that there is not collision with extraReducers ?
    return combineReducers(Object.assign(out, extraReducers));
}

function buildReducer(model) {
    const reducers = model.reducers;
    const reducerNames = Object.keys(model.reducers);
    const initialState = JSON.parse(JSON.stringify(model.state)); //TODO use deep-clone ?
    
    return function(state = initialState, action) {
        for (let k=0; k<reducerNames.length; ++k) {
            const reducerName = reducerNames[k];
            
            if (action.type === `${model.name}::${reducerName}`) {
                return reducers[reducerName](state, action.payload, action.error, action.meta);
            }
        }
        return state;
    };
}

function buildActions(modelName, reducers, actions = {}) {
    const out = Object.assign({}, actions || {});
    
    Object.keys(reducers).forEach(k => {
        out[k] = (payload, error=false, meta=null) => ({
            type: `${modelName}::${k}`,
            payload: payload,
            error: error,
            meta: meta,
        });
    });
    
    return out;
}
