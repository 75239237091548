import React from 'react'; //eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import {connect as redux_connect} from 'react-redux';

import {AppLogoSmall} from '@/components/static/app-logo-small';
import {UserMenu} from '@/components/widgets/user-menu';

@redux_connect(
    state => ({
        me: state.me,
        windowSize: state.windowSize,
    })
)
class MainTopbar extends React.Component {
    static propTypes = {
        windowSize: PropTypes.object,
        me: PropTypes.object,
    };

    render() {
        const props = this.props;

        return (
            <header className="bg-background-third flex">
                <div className="px-3 py-4">
                    <AppLogoSmall/>
                </div>
                <h1 className="text-2xl font-semibold grow px-5 py-6 border-l-4 border-background">{(props.windowSize.W >= 768) ? 'Gabi Documents Manager' : 'GDM'}</h1>
                <UserMenu className="topbar-user-menu p-3" user={props.me} direction="bottom" picLocation="right" />
            </header>
        );
    }

    shouldComponentUpdate() {
        return true; // Layout component, has children
    }
}

export { MainTopbar };
