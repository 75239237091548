import autobind from 'autobind-decorator';
import deepEqual from 'deep-eql';
import {collection, getDocs, orderBy, query} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React from 'react';
import {connect as redux_connect} from 'react-redux/es/exports';

import searchIcon from '@/assets/icons/search-icon.svg';
import {CreateIdentifier} from '@/components/business/documents/create-identifier';
import {DocumentsEditable} from '@/components/business/documents/documents-editable';
import {ErrorBoundary} from '@/components/errors/error-boundary';
import {Modal} from '@/components/layout/modal';
import {LoadingView} from '@/components/static/loading-view';
import {db} from '@/service/firebase';

import config from '../config';

let tokenClient;
let accessToken = null;

@redux_connect(
    state => ({
        me: state.me,
        windowSize: state.windowSize,
    })
)

class Documents extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        windowSize: PropTypes.object,
    };

    state = {
        gapiInited: false,
        gisInited: false,
        pickerInited: false,
        accessToken: null,
        showCreateIdentifierModal: false,
        showSelectDocumentModal: false,
        selectedDocumentUrl: '',
        selectedDocument: null,
        documents: null,
        filteredDocuments: null,
        searchIdentifier: '',
        windowSizeMobile: '768',
        loading: true,
    };

    componentDidMount() {
        // Create API script
        const scriptApi = document.createElement('script');
        scriptApi.src = 'https://apis.google.com/js/api.js';
        scriptApi.async = true;
        scriptApi.defer = true;
        scriptApi.onload = this.gapiLoaded;
        document.body.appendChild(scriptApi);

        // Create gis script
        const scriptGis = document.createElement('script');
        scriptGis.src = 'https://accounts.google.com/gsi/client';
        scriptGis.async = true;
        scriptGis.defer = true;
        scriptGis.onload = this.gisLoaded;
        document.body.appendChild(scriptGis);
    }

    componentDidUpdate() {
        if(this.state.gisInited && this.state.gapiInited && this.state.pickerInited && this.state.accessToken && this.state.documents === null) {
            this.fetchDocuments();
        }
    }

    render() {
        const props = this.props;
        const state = this.state;

        return (
            <div className={props.className}>
                <ErrorBoundary>
                    {!state.loading && state.documents &&
                        <div>
                            <div className="flex flex-wrap my-8">
                                {props.windowSize.W >= state.windowSizeMobile && <button className="bg-green hover:bg-green-hover text-white py-2 px-4 rounded-md mr-3" onClick={this.handleClickCreateIdentifier}>Create identifier</button>}
                                {props.windowSize.W >= state.windowSizeMobile && state.showSelectDocumentModal &&
                                    <Modal title="Create identifier" onClosed={this.handleCloseModal}>
                                        <div className="flex">
                                            <form className="px-10 border-r-background-third border-r-2" method="post" action="#" onSubmit={this.handleSelectDocumentSubmit}>
                                                <h4 className="font-bold mb-3">Copy a document ID or URL</h4>
                                                <input className="block shadow appearance-none border border-dark rounded py-2.5 px-4 text-front leading-tight focus:outline-none focus:shadow-outline bg-background-third" type="text" name="selectDocumentUrl" id="selectDocumentUrl" placeholder="Document ID or URL" value={state.selectedDocumentUrl} onChange={this.handleChangeSelectedDocumentUrl} />
                                                <button className="block bg-green hover:bg-green-hover text-white py-2 px-4 rounded-md mr-3 mt-3" onClick={this.handleSelectDocumentSubmit}>Create identifier</button>
                                            </form>
                                            <div className="px-10">
                                                <h4 className="font-bold mb-3">Or use the Google Drive Picker</h4>
                                                <button className="bg-green hover:bg-green-hover text-white py-2 px-4 rounded-md mr-3" onClick={this.createPicker}>Open Google Drive Picker</button>
                                            </div>
                                        </div>
                                    </Modal>
                                }
                                {props.windowSize.W >= state.windowSizeMobile && state.showCreateIdentifierModal &&
                                    <CreateIdentifier selectedDocument={state.selectedDocument} onClosed={this.handleCreateIdentifierClosed} onIdentifierCreated={this.handleIdentifierCreated} />
                                }

                                <form className="relative" onSubmit={this.handleSearchIdentifierSubmit}>
                                    <input className="shadow appearance-none border border-dark rounded py-2.5 px-4 text-front leading-tight focus:outline-none focus:shadow-outline bg-background-third" type="text" name="search-identifier" placeholder="Search identifier..." value={state.searchIdentifier} onChange={this.handleChangeSearchIdentifier} />
                                    {state.searchIdentifier && <button type="button" className="absolute right-11 top-[8px] z-20 bg-background hover:text-front py-1.5 px-2 rounded-full leading-3 text-clear" onClick={this.handleClickClearSearch}>&times;</button>}
                                    <button type="button" className="absolute right-5 top-3 z-10" onClick={this.handleSearchIdentifierSubmit}>
                                        <img src={searchIcon} width={14} alt="Search" />
                                    </button>
                                </form>
                            </div>

                            <DocumentsEditable documents={state.filteredDocuments || state.documents} />
                        </div>
                    }
                    {state.loading &&
                        <div className="w-full my-16">
                            <LoadingView size="medium" />
                        </div>
                    }
                </ErrorBoundary>
            </div>
        );
    }

    @autobind
    handleSearchIdentifierSubmit(e) {
        e.preventDefault();
        this.filterDocuments();
    }

    @autobind
    handleClickClearSearch() {
        this.setState(state => ({
            ...state,
            searchIdentifier: '',
            filteredDocuments: null,
        }));
    }

    @autobind
    handleChangeSearchIdentifier(e) {
        this.setState(state => ({
            ...state,
            searchIdentifier: e.target.value,
        }), function() {
            this.filterDocuments();
        });
    }

    @autobind
    async handleChangeSelectedDocumentUrl(e) {
        this.setState(state => ({
            ...state,
            selectedDocumentUrl: e.target.value,
        }));
    }

    @autobind
    handleClickCreateIdentifier() {
        this.setState(state => ({
            ...state,
            showSelectDocumentModal: true,
        }));
    }

    @autobind
    handleCloseModal() {
        this.setState(state => ({
            ...state,
            showSelectDocumentModal: false,
        }));
    }

    @autobind
    filterDocuments() {
        const filteredDocuments = [];
        const searchIdentifier = this.state.searchIdentifier.toLowerCase();
        this.state.documents.forEach(document => {
            if(document.identifier.toLowerCase().includes(searchIdentifier) || document.name.toLowerCase().includes(searchIdentifier)) {
                filteredDocuments.push(document);
            }
        });

        this.setState(state => ({
            ...state,
            filteredDocuments: filteredDocuments,
        }));
    }

    @autobind
    handleCreateIdentifierClosed() {
        this.setState(state => ({
            ...state,
            showCreateIdentifierModal: false,
        }));
    }

    @autobind
    handleIdentifierCreated() {
        this.handleCreateIdentifierClosed();
        this.fetchDocuments();
    }

    @autobind
    gapiLoaded() {
        console.debug('gapiLoaded');
        try {
            gapi.load('client', this.initializeGapiClient);
            gapi.load('picker', this.initializePicker);
        }
        catch(error) {
            console.error(error);
        }
    }

    @autobind
    initializePicker() {
        this.setState(state => ({
            ...state,
            pickerInited: true,
        }));
    }

    @autobind
    async gisLoaded() {
        console.debug('gisLoaded');
        try {
            tokenClient = google.accounts.oauth2.initTokenClient({
                client_id: config.gapi.clientId,
                scope: config.gapi.scopes,
                prompt: '',
                callback: (tokenResponse) => {
                    accessToken = tokenResponse.access_token;
                    this.setState(state => ({
                        ...state,
                        accessToken: accessToken,
                    }));
                },
            });

            await tokenClient.requestAccessToken();

            this.setState(state => ({
                ...state,
                gisInited: true,
            }));
        }
        catch(error) {
            console.error(error);
        }
    }

    /**
     * Callback after the API client is loaded. Loads the
     * discovery doc to initialize the API.
     */
    @autobind
    async initializeGapiClient() {
        try {
            await gapi.client.init({
                apiKey: config.gapi.apiKey,
                discoveryDocs: [config.gapi.discoveryDocs],
            });

            this.setState(state => ({
                ...state,
                gapiInited: true,
            }));
        }
        catch(error) {
            console.error(error);
        }
    }

    /**
     *  Create and render a Picker object for searching documents.
     */
    @autobind
    createPicker() {
        this.setState(state => ({
            ...state,
            showSelectDocumentModal: false,
        }));

        const DHFPediarity = new google.picker.DocsView(google.picker.ViewId.DOCS)
            .setIncludeFolders(true).setMode(google.picker.DocsViewMode.LIST)
            .setSelectFolderEnabled(false)
            .setLabel('DHF Pediarity')
            .setParent('0AFd-5-UtrpadUk9PVA');

        const DHFAISaMD = new google.picker.DocsView(google.picker.ViewId.DOCS)
            .setIncludeFolders(true).setMode(google.picker.DocsViewMode.LIST)
            .setSelectFolderEnabled(false)
            .setLabel('DHF AI SaMD')
            .setParent('0ALb0FoRok9_pUk9PVA');

        const QMS = new google.picker.DocsView(google.picker.ViewId.DOCS)
            .setIncludeFolders(true).setMode(google.picker.DocsViewMode.LIST)
            .setSelectFolderEnabled(false)
            .setLabel('QMS')
            .setParent('0AODYg7e2NA4MUk9PVA');

        let driveTest = null;
        if(GABI_ENV === 'development' || GABI_ENV === 'acceptance') {
            driveTest = new google.picker.DocsView(google.picker.ViewId.DOCS)
                .setIncludeFolders(true).setMode(google.picker.DocsViewMode.LIST)
                .setSelectFolderEnabled(false)
                .setLabel('driveTest')
                .setParent('0AApnfQpmvFrlUk9PVA');
        }

        const picker = new google.picker.PickerBuilder()
            .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
            .addView(DHFPediarity)
            .addView(DHFAISaMD)
            .addView(QMS)
            .setOAuthToken(accessToken)
            .setDeveloperKey(config.gapi.apiKey)
            .setCallback(this.pickerCallback)
            .setMaxItems(1)
            .setTitle('Select a document')
            .build();
        picker.setVisible(true);

        if(driveTest && GABI_ENV === 'development' || GABI_ENV === 'acceptance') {
            picker.addView(driveTest);
        }
    }

    @autobind
    async handleSelectDocumentSubmit(e) {
        e.preventDefault();

        const sharedDrives = ['0AFd-5-UtrpadUk9PVA','0ALb0FoRok9_pUk9PVA','0AODYg7e2NA4MUk9PVA', '0AApnfQpmvFrlUk9PVA']; // Shared drives allowed
        const document = this.state.selectedDocumentUrl;

        const documentId = (document.includes('/')) ? this.state.selectedDocumentUrl.split('/')[5] : document;
        const selectedDocument = {};

        const googleDriveFile = await gapi.client.drive.files.get({
            'fileId': documentId,
            'fields': 'id,name,webViewLink,parents,trashed,teamDriveId',
            supportsAllDrives: true,
        });

        console.debug('googleDriveFile', googleDriveFile);

        const googleDriveFileParent = await gapi.client.drive.files.get({
            'fileId': googleDriveFile.result.parents[0],
            'fields': 'id,name,webViewLink',
            supportsAllDrives: true,
        });

        console.debug('googleDriveFileParent', googleDriveFileParent);

        selectedDocument.id = documentId;
        selectedDocument.name = googleDriveFile.result.name;
        selectedDocument.nameValid = true;
        selectedDocument.url = googleDriveFile.result.webViewLink;
        selectedDocument.isShared = sharedDrives.includes(googleDriveFile.result.teamDriveId);
        selectedDocument.parentId = googleDriveFileParent.result.id;
        selectedDocument.parentName = googleDriveFileParent.result.name;
        selectedDocument.parentUrl = googleDriveFileParent.result.webViewLink;

        console.debug('Selected document (manual)', selectedDocument);

        this.setState(state => ({
            ...state,
            showCreateIdentifierModal: true,
            showSelectDocumentModal: false,
            selectedDocumentUrl: '',
            selectedDocument: selectedDocument,
        }));
    }

    /**
     * Displays the file details of the user's selection.
     * @param {object} data - Containers the user selection from the picker
     */
    @autobind
    pickerCallback(data) {
        if (data.action === google.picker.Action.PICKED) {
            console.debug('Selected document (picker)', data.docs[0]);
            this.setState(state => ({
                ...state,
                showCreateIdentifierModal: true,
                selectedDocument: data.docs[0],
            }));
        }
    }

    async fetchDocuments() {
        try {
            this.setState(state => ({
                ...state,
                documents: [],
                loading: true,
            }));

            // Get documents collection on Firestore
            const documents = [];
            const myCollRef = collection(db, 'documents');
            const myQuery = query(myCollRef, orderBy('identifier', 'desc'));
            const mySnapshot = await getDocs(myQuery);

            mySnapshot.forEach((doc) => {
                documents.push({
                    identifier: doc.data().identifier,
                    documentId: doc.data().documentId,
                    name: doc.data().name,
                    nameValid: doc.data().nameValid,
                    url: doc.data().url,
                    mimeType: doc.data().mimeType,
                    parentId: doc.data().parentId,
                    parentName: doc.data().parentName,
                    parentUrl: doc.data().parentUrl,
                    trashed: doc.data().trashed,
                    deleted: doc.data().deleted,
                    hidden: false,
                });
            });

            this.setState(state => ({
                ...state,
                loading: false,
                documents: documents,
            }));
        }
        catch (error) {
            console.error(error);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

export { Documents };
