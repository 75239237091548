import autobind from 'autobind-decorator';
import {collection, getDocs, setDoc, doc, query, orderBy, limit} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React from 'react';

import defaultIcon from '@/assets/icons/mimetype_default.svg';
import documentIcon from '@/assets/icons/mimetype_document.svg';
import imageIcon from '@/assets/icons/mimetype_image.svg';
import pdfIcon from '@/assets/icons/mimetype_pdf.svg';
import spreadsheetIcon from '@/assets/icons/mimetype_spreadsheet.svg';
import {Modal} from '@/components/layout/modal';
import {LoadingView} from '@/components/static/loading-view';
import {db} from '@/service/firebase';

const mimeType = {
    'application/pdf': pdfIcon,
    'application/vnd.google-apps.document': documentIcon,
    'application/vnd.google-apps.spreadsheet': spreadsheetIcon,
    'image/png': imageIcon,
};

class CreateIdentifier extends React.Component {
    static propTypes = {
        selectedDocument: PropTypes.object.isRequired,
        onIdentifierCreated: PropTypes.func.isRequired,
        onClosed: PropTypes.func.isRequired,
    };

    state = {
        loading: false,
    };

    render() {
        const props = this.props;
        const state = this.state;

        return (
            <div>
                <Modal title="Create a new identifier" onClosed={this.handleCloseModal}>
                    {!state.loading &&
                        <div>
                            <h3 className="mb-4">Your selection:</h3>
                            <div className="p-3 rounded-md bg-background-third">
                                <img className="inline-block mr-4" src={mimeType[props.selectedDocument.mimeType] || defaultIcon} alt={props.selectedDocument.mimeType} width={20} />
                                <a href={props.selectedDocument.url} target="_blank" rel="noreferrer">{props.selectedDocument.name}</a>
                            </div>

                            {props.selectedDocument.isShared &&
                                <button className="bg-green hover:bg-green-hover text-white py-2 px-4 rounded-md my-8" onClick={this.createIdentifier}>
                                    Create identifier for this document
                                </button>
                            }
                            {!props.selectedDocument.isShared &&
                                <p className="bg-red px-5 py-3 mt-6 rounded-md">This is not a shared document.</p>
                            }
                        </div>
                    }
                    {state.loading &&
                        <LoadingView size="small" />
                    }
                </Modal>
            </div>
        );
    }

    @autobind
    handleCloseModal() {
        if(this.props.onClosed) {
            this.props.onClosed();
        }
    }

    @autobind
    async createIdentifier() {
        const props = this.props;
        let googleDriveFile;

        this.setState(state => ({
            ...state,
            loading: true,
        }));

        try {
            const myCollRef = collection(db, 'documents');
            const myQuery = query(myCollRef, orderBy('identifier', 'desc'), limit(1));
            const documents = await getDocs(myQuery);
            let lastIdentifier;

            documents.forEach((myDoc) => {
                lastIdentifier = parseInt(myDoc.data().identifier.substring(2, 7)) + 1;
            });

            const newIdentifier = (lastIdentifier !== undefined) ? `GD${lastIdentifier.toLocaleString(undefined, {useGrouping: false, minimumIntegerDigits: 5})}` : 'GD00001';

            if(props.selectedDocument.name.substring(0, 7) !== `${newIdentifier}-`) {
                const body = {'name': `${newIdentifier}-${props.selectedDocument.name}`};
                await gapi.client.drive.files.update({
                    fileId: props.selectedDocument.id,
                    resource: body,
                    supportsAllDrives: true,
                });
            }

            googleDriveFile = await gapi.client.drive.files.get({
                'fileId': props.selectedDocument.id,
                'fields': 'id,name,parents,mimeType,modifiedTime,size,webViewLink',
                supportsAllDrives: true,
            });

            const googleDriveFileParent = await gapi.client.drive.files.get({
                'fileId': googleDriveFile.result.parents[0],
                'fields': 'id,name,webViewLink',
                supportsAllDrives: true,
            });

            await setDoc(doc(db, 'documents', newIdentifier), {
                identifier: newIdentifier,
                documentId: googleDriveFile.result.id,
                name: googleDriveFile.result.name,
                nameValid: true,
                mimeType: googleDriveFile.result.mimeType,
                url: googleDriveFile.result.webViewLink,
                parentId: googleDriveFileParent.result.id,
                parentName: googleDriveFileParent.result.name,
                parentUrl: googleDriveFileParent.result.webViewLink,
            });

            if(this.props.onIdentifierCreated) {
                this.props.onIdentifierCreated();
            }

            this.setState(state => ({
                ...state,
                loading: false,
            }));
        } catch (error) {
            console.error(error);
        }
    }
}

export { CreateIdentifier };
