import PropTypes from 'prop-types';
import React from 'react'; //eslint-disable-line no-unused-vars

class LoadingView extends React.Component {
    static propTypes = {
        size: PropTypes.string,
    };

    render() {
        const props = this.props;

        return (
            <div className="flex items-center justify-center">
                {(!props.size || props.size === 'small') &&
                    <div className="flex items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
                    </div>
                }
                {props.size === 'medium' &&
                    <div className="flex items-center justify-center ">
                        <div className="w-24 h-24 border-l-2 border-gray-900 rounded-full animate-spin"></div>
                    </div>
                }
                {props.size === 'large' &&
                    <div className="flex items-center justify-center ">
                        <div className="w-40 h-40 border-t-4 border-b-4 border-green-900 rounded-full animate-spin"></div>
                    </div>
                }
            </div>
        );
    }
}

export { LoadingView };
