import React from 'react'; //eslint-disable-line no-unused-vars
import autobind from 'autobind-decorator';
import {GoogleAuthProvider, signInWithRedirect} from 'firebase/auth';
import PropTypes from 'prop-types';
import {connect as redux_connect} from 'react-redux/es/exports';

import loginImage from '@/assets/images/login.svg';
import logoImage from '@/assets/images/logo.svg';
import {auth} from '@/service/firebase';

@redux_connect(
    state => ({
        windowSize: state.windowSize,
    })
)

class Login extends React.Component {
    static propTypes = {
        notAllowedError: PropTypes.bool,
        windowSize: PropTypes.object,
    };

    state = {
        loading: false,
        windowSizeMobile: '768',
        errorMessage: '',
    };

    render() {
        const props = this.props;
        const state = this.state;

        return (
            <div className="flex flex-row items-center">
                {props.windowSize.W >= state.windowSizeMobile &&
                <div className="basis-1/2 m-48">
                    <img src={loginImage} alt="Illustration of people working with documents"/>
                </div>
                }
                <div className={(props.windowSize.W >= state.windowSizeMobile) ? 'basis-1/2 m-32' : 'p-10'}>
                    <img src={logoImage} alt="Gabi Documents" width={400}/>

                    {!state.loading &&
                    <button className="bg-green hover:bg-green-hover text-white py-2 px-4 rounded-full my-8" onClick={this.signInWithGoogle}>
                        Sign-in with Google
                    </button>
                    }
                    {state.loading &&
                    <div>
                        <div style={{borderTopColor:'transparent'}} className="w-16 h-16 border-4 border-blue-400 border-solid rounded-full animate-spin"></div>
                    </div>
                    }

                    {props.notAllowedError &&
                    <div role="alert">
                        <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                            Forbidden
                        </div>
                        <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                            <p>You are not allowed to access this application.</p>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }

    @autobind
    signInWithGoogle() {
        const provider = new GoogleAuthProvider();

        this.setState(state => ({
            ...state,
            loading: true,
        }));

        signInWithRedirect(auth, provider)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.error(error);
            });
    }
}

export { Login };
