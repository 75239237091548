import { asyncModel } from '@/store/simpler-redux';

const me = asyncModel({
    name: 'me',
    state: null,
    reducers: {
    },
    actions: {
    },
});

export {me};
