import { model } from '@/store/simpler-redux';

/**
 * Contains the GET routes for each defined model. The keys of this object must match a model defined in /store/models
 */
export const fetchMap = {
    settings: { service: ':client', path: '/settings' },
};
//----------------------------------------------------------------------------------------------------------------------

const models = {
    'backend-api': model({
        name: 'backend-api',
        state: {},
        reducers: {
            clear: () => {
                return {};
            },
    
            prepare: (state, data) => {
                data = Object.assign({}, data);
        
                const newState = { ...state };
                newState[data.request] = data;
                return newState;
            },
    
            update: (state, data) => {
                data = Object.assign({}, data);
        
                const newState = { ...state };
                newState[data.request] = Object.assign({}, newState[data.request], data);
                return newState;
            },
    
            remove: (state, data) => {
                data = Object.assign({}, data);
        
                const newState = { ...state };
                delete newState[data.request];
                return newState;
            },
        },
    })
};

export { models };
