import { model } from '@/store/simpler-redux';

const currentPage = model({
    name: 'currentPage',
    state: '',
    reducers: {
        set: (state, data) => {
            return data;
        },
    },
});

export {currentPage};
