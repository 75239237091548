import autobind from 'autobind-decorator';
import deepEqual from 'deep-eql';
import { signOut } from 'firebase/auth';
import PropTypes from 'prop-types';
import React from 'react';

import { auth } from '@/service/firebase';

class UserMenu extends React.Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        picLocation: PropTypes.oneOf(['left', 'right']) //default: right
    };
    
    render() {
        const props = this.props;
        const user = props.user.data || {};

        return (
            <div className="user-menu border-l-4 border-background px-10 py-3">
                <div className="user-wrapper">
                    <div className="d-none d-md-inline-block">
                        <strong>{(user.displayName) ? user.displayName : user.email}</strong><br/>
                        <span className="user-menu-app-version">v{APP_VERSION}</span><br/>
                        <a className="menu-item-logout cursor-pointer" onClick={this.handleClickSignOut}>Log out</a>
                    </div>
                </div>
            </div>
        );
    }
    
    @autobind
    handleClickSignOut(e) {
        e.preventDefault();

        signOut(auth).then(() => {
            globalThis.window.location.reload();
        }).catch((error) => {
            console.error(error);
        });
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

export { UserMenu };

