import {onAuthStateChanged} from 'firebase/auth';
import React from 'react';

import {MainLayout} from './components/layout/main-layout';
import {Documents} from './pages/documents';
import {Login} from './pages/login';
import { auth } from './service/firebase';
import { store } from './store';
import * as specificModels from './store/models';
import {windowSize} from './store/models';
import {AsyncStatus} from './store/simpler-redux';

console.log(`Gabi environment: "${GABI_ENV}"`);

class App extends React.Component {
    appBundle = null;

    state = {
        user: null,
        appLoaded: false,
    };

    componentDidMount() {
        const model = specificModels.me;
        onAuthStateChanged(auth, (user) => {
            console.log('onAuthStateChanged', user);
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                this.setState({
                    user: uid,
                    userAllowed: user.email.includes('@gabismartcare.com')
                });
                store.dispatch(model.actions.set(user, null, { status: AsyncStatus.FETCHED }));
                console.log(uid);
                // ...
            } else {
                // eslint-disable-next-line no-undef
                store.dispatch(model.actions.set(null, 'no user', { status: AsyncStatus.ERROR }));
                console.log('no user');
            }
        });
    }

    render() {
        const state = this.state;
        console.log(auth);

        if(state.user) {
            if(state.userAllowed) {
                return (
                    <MainLayout>
                        <Documents />
                    </MainLayout>
                );
            } else {
                return (
                    <Login notAllowedError={true} />
                );
            }
        } else {
            return (
                <Login />
            );
        }
    }
}

// Resize detection
let updateTimeout = null;
globalThis.addEventListener('resize', () => {
    if (updateTimeout) {
        clearTimeout(updateTimeout);
    }
    updateTimeout = setTimeout(() => {
        store.dispatch(windowSize.actions.update());
    }, 100);
});

export default App;
