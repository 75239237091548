import autobind from 'autobind-decorator';
import PropTypes from 'prop-types';
import React from 'react';

class Modal extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        onClosed: PropTypes.func,
        children: PropTypes.any,
    };

    render() {
        const props = this.props;

        return (
            <div className="">
                <div className="modal-content fixed top-10 left-1/2 -translate-x-1/2 bg-background py-5 px-5 rounded-md border-none z-30 min-w-min max-w-max">
                    <button type="button" className="border-none bg-none absolute right-5 top-5 text-2xl hover:text-active" onClick={this.handleCloseModal}>&times;</button>
                    <h3 className="text-xl">{props.title}</h3>
                    <div className="modal-body relative p-4">
                        {props.children}
                    </div>
                </div>
                <div className="modal-overlay fixed top-0 left-0 bg-background-third opacity-80 z-20 w-full h-full " onClick={this.handleCloseModal} />
            </div>
        );
    }

    @autobind
    handleCloseModal() {
        if(this.props.onClosed) {
            this.props.onClosed();
        }
    }

    shouldComponentUpdate() {
        return true; // Layout component, has children
    }
}

export { Modal };

