import autobind from 'autobind-decorator';
import PropTypes from 'prop-types';
import React from 'react';
import {connect as redux_connect} from 'react-redux/es/exports';

import folderIcon from '@/assets/icons/folder-icon.svg';
import copyIcon from '@/assets/icons/icon-copy.svg';
import downloadIcon from '@/assets/icons/icon-download.svg';
import searchFolderIcon from '@/assets/icons/icon-search-folder.svg';
import defaultIcon from '@/assets/icons/mimetype_default.svg';
import documentIcon from '@/assets/icons/mimetype_document.svg';
import imageIcon from '@/assets/icons/mimetype_image.svg';
import pdfIcon from '@/assets/icons/mimetype_pdf.svg';
import spreadsheetIcon from '@/assets/icons/mimetype_spreadsheet.svg';

const mimeType = {
    'application/pdf': pdfIcon,
    'application/vnd.google-apps.document': documentIcon,
    'application/vnd.google-apps.spreadsheet': spreadsheetIcon,
    'image/png': imageIcon,
};

@redux_connect(
    state => ({
        windowSize: state.windowSize,
    })
)

class DocumentsRelated extends React.Component {
    static propTypes = {
        relatedDocuments: PropTypes.array.isRequired,
        selectedDocumentId: PropTypes.string.isRequired,
        windowSize: PropTypes.object,
    };

    state = {
        copiedRelatedDocument: null,
        windowSizeMobile: '768',
    };

    render() {
        const props = this.props;
        const state = this.state;

        if(props.relatedDocuments) {
            return (
                <div>
                    <h2><img className="inline-block mr-3" src={searchFolderIcon} width={15} alt="Related files" /> {props.selectedDocumentId} related documents</h2>
                    {props.relatedDocuments.length > 0 &&
                        <table className="table-auto w-full mt-4">
                            <tbody>
                                {props.relatedDocuments.map(relatedDocument => (
                                    <tr key={relatedDocument.id}>
                                        <td className="py-2 px-1 border-b border-background-secondary">
                                            <img src={mimeType[relatedDocument.mimeType] || defaultIcon} alt={document.mimeType} width={20}/>
                                        </td>
                                        <td className="py-2 px-1 border-b border-background-secondary">
                                            <a href={relatedDocument.webViewLink}
                                                target="_blank" rel="noreferrer">{relatedDocument.name}</a>
                                        </td>
                                        {props.windowSize.W >= state.windowSizeMobile &&
                                        <td className="py-2 px-1 border-b border-background-secondary">
                                            <a href={relatedDocument.parentWebViewLink} className="flex" target="_blank" rel="noreferrer">
                                                <img src={folderIcon} className="mr-2" width={15} alt="Folder icon"/>
                                                {relatedDocument.parentName}
                                            </a>
                                        </td>
                                        }
                                        <td className="py-2 px-1 border-b border-background-secondary align-middle">
                                            <button className="inline-block rounded-full bg-background-secondary text-center px-2.5 py-2 hover:bg-active"
                                                title="Copy document sharing url"
                                                onClick={() => this.onClickCopyDocumentUrl(relatedDocument.id, relatedDocument.webViewLink)}>
                                                <img src={copyIcon} width={15} alt="Copy document url"/>
                                            </button>
                                            {state.copiedRelatedDocument === relatedDocument.id &&
                                            <span className="rounded-full bg-background-secondary right-[190px] px-3 py-1 ml-3 absolute animate__animated animate__slideInLeft">Document&apos;s sharing url copied!</span>
                                            }
                                        </td>
                                        <td className="py-2 px-1 border-b border-background-secondary align-middle">
                                            <a href={relatedDocument.webViewLink} target="_blank"
                                                className="inline-block rounded-full bg-background-secondary text-center px-2.5 py-2 hover:bg-active" rel="noreferrer">
                                                <img src={downloadIcon} width={11} alt="Download document"/>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                    {props.relatedDocuments.length === 0 &&
                        <p className="bg-active px-5 py-3 mt-6 rounded-md">No related documents.</p>
                    }
                </div>
            );
        }
    }

    @autobind
    onClickCopyDocumentUrl(documentId, documentUrl) {
        navigator.clipboard.writeText(documentUrl)
            .then(() => {
                this.setState(state => ({
                    ...state,
                    copiedRelatedDocument: documentId,
                }));

                setTimeout(() => this.setState(state => ({
                    ...state,
                    copiedRelatedDocument: null,
                })), 3000);

                return documentId;
            })
            .catch(error => {
                console.log(error);
                return false;
            });
    }
}

export { DocumentsRelated };
