import React from 'react'; //eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'; //eslint-disable-line no-unused-vars
import autobind from 'autobind-decorator';
import {connect as redux_connect} from 'react-redux/es/exports';

import folderIcon from '@/assets/icons/folder-icon.svg';
import copyIcon from '@/assets/icons/icon-copy.svg';
import searchFolderIcon from '@/assets/icons/icon-search-folder.svg';
import warningIcon from '@/assets/icons/icon-warning.svg';
import defaultIcon from '@/assets/icons/mimetype_default.svg';
import documentIcon from '@/assets/icons/mimetype_document.svg';
import imageIcon from '@/assets/icons/mimetype_image.svg';
import pdfIcon from '@/assets/icons/mimetype_pdf.svg';
import spreadsheetIcon from '@/assets/icons/mimetype_spreadsheet.svg';
import trashIcon from '@/assets/icons/trash-icon.svg';
import {DocumentsRelated} from '@/components/business/documents/documents-related';
import {LoadingView} from '@/components/static/loading-view';

const mimeType = {
    'application/pdf': pdfIcon,
    'application/vnd.google-apps.document': documentIcon,
    'application/vnd.google-apps.spreadsheet': spreadsheetIcon,
    'image/png': imageIcon,
};

@redux_connect(
    state => ({
        windowSize: state.windowSize,
    })
)

class DocumentsEditable extends React.Component {
    static propTypes = {
        windowSize: PropTypes.object,
        documents: PropTypes.array.isRequired,
    };

    state = {
        relatedDocuments: null,
        selectedDocumentId: null,
        copiedDocument: null,
        windowSizeMobile: '768',
        loading: false,
    };

    componentDidUpdate(prevProps) {
        if(this.props.documents !== prevProps.documents) {
            // eslint-disable-next-line no-unused-vars
            this.setState(state => ({
                ...state,
                relatedDocuments: null,
                selectedDocumentId: null,
            }));
        }
    }

    render() {
        const props = this.props;
        const state = this.state;
        
        console.debug('documents', props.documents);

        if(props.documents) {
            if(props.windowSize.W >= state.windowSizeMobile) {
                return (
                    <div className="flex flew-wrap">
                        <div className="w-1/2 mr-16">
                            {props.documents.length > 0 &&
                            <table className="table-auto w-full">
                                <thead>
                                    <tr>
                                        <td className="py-2 px-3">Identifier</td>
                                        <td className="py-2">Type</td>
                                        <td className="py-2">Filename</td>
                                        <td className="py-2">Location</td>
                                        <td className="py-2"></td>
                                        <td className="py-2"></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.documents.map(document => (
                                        <tr key={document.identifier} className={(state.selectedDocumentId === document.identifier) ? 'bg-background-secondary' : ''}>
                                            <td className="py-3 px-1 border-t border-background-third">
                                                {document.identifier}
                                            </td>
                                            <td className="py-3 px-1 border-t border-background-third">
                                                <img src={mimeType[document.mimeType] || defaultIcon} alt={document.mimeType} width={20}/>
                                            </td>
                                            <td className="py-3 px-1 border-t border-background-third">
                                                {(document.deleted) ?
                                                    <span className="text-red line-through">{document.name}</span>
                                                    :
                                                    <a href={document.url} target="_blank" rel="noreferrer">{document.name}</a>
                                                }

                                                {!document.nameValid &&
                                                    <span>
                                                        <img
                                                            className="ml-3 inline-block"
                                                            src={warningIcon}
                                                            width={20}
                                                            title={`The name of this document is not valid, it should starts with "${document.identifier}-"`}
                                                            alt="Warning icon"
                                                        />
                                                    </span>
                                                }
                                            </td>
                                            <td className="py-3 px-1 border-t border-background-third">
                                                {(document.deleted) ?
                                                    <div className="flex text-red">
                                                        <img src={trashIcon} className="mr-2" width={15} alt="Folder icon" />
                                                        Deleted
                                                    </div>
                                                    : (document.trashed) ?
                                                        <a href="https://drive.google.com/drive/trash" className="flex text-red visited:text-red" target="_blank" rel="noreferrer">
                                                            <img src={trashIcon} className="mr-2" width={15} alt="Folder icon" />
                                                        Trash
                                                        </a>
                                                        :
                                                        <a href={document.parentUrl} className="flex" target="_blank" rel="noreferrer">
                                                            <img src={folderIcon} className="mr-2" width={15} alt="Folder icon" />
                                                            {document.parentName}
                                                        </a>
                                                }
                                            </td>
                                            <td className="py-3 px-1 border-t border-background-third align-middle">
                                                <button
                                                    className="rounded-full bg-background-third p-2 hover:bg-active"
                                                    title="Copy document sharing url"
                                                    onClick={() => this.onClickCopyDocumentUrl(document.identifier, document.url)}
                                                >
                                                    <img src={copyIcon} width={15} alt="Copy document url"/>
                                                </button>
                                                {state.copiedDocument === document.identifier &&
                                                    <span className="rounded-full bg-background-secondary px-3 py-1 ml-3 absolute animate__animated animate__slideInRight">Document&apos;s sharing url copied!</span>
                                                }
                                            </td>
                                            <td className="py-3 px-1 border-t border-background-third align-middle">
                                                <button
                                                    className={`${(state.selectedDocumentId === document.identifier) ? 'bg-active' : 'bg-background-third'} rounded-full  p-2 hover:bg-active`}
                                                    onClick={() => this.onClickRelatedFiles(document.identifier, document.name)}>
                                                    <img src={searchFolderIcon} width={15} alt="Related files"/>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            }
                            {props.documents.length === 0 &&
                                <div className="w-full">
                                    <p className="bg-active px-5 py-3 mt-6 rounded-md">No documents.</p>
                                </div>
                            }
                        </div>
                        {!state.loading && state.relatedDocuments &&
                            <div className="w-1/2 bg-background-third py-10 px-10 mt-5">
                                <DocumentsRelated relatedDocuments={state.relatedDocuments} selectedDocumentId={state.selectedDocumentId} />
                            </div>
                        }
                        {state.loading &&
                            <div className="w-1/2 bg-background-third py-10 px-10 mt-5">
                                <LoadingView size="small" />
                            </div>
                        }
                    </div>
                );
            } else {
                return (
                    <div>
                        {!state.relatedDocuments && !state.loading &&
                            <div>
                                {props.documents.length > 0 &&
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr>
                                            <td className="py-2 px-3">Identifier</td>
                                            <td className="py-2">Type</td>
                                            <td className="py-2">Filename</td>
                                            <td className="py-2">Location</td>
                                            <td className="py-2"></td>
                                            <td className="py-2"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.documents.map(document => (
                                            <tr key={document.identifier}
                                                className={(state.selectedDocumentId === document.identifier) ? 'bg-background-secondary' : ''}>
                                                <td className="py-3 px-1 border-t border-background-third">
                                                    {document.identifier}
                                                </td>
                                                <td className="py-3 px-1 border-t border-background-third">
                                                    <img src={mimeType[document.mimeType] || defaultIcon} alt={document.mimeType}
                                                        width={20}/>
                                                </td>
                                                <td className="py-3 px-1 border-t border-background-third">
                                                    <a href={document.url} target="_blank" rel="noreferrer">{document.name}</a>
                                                    {!document.nameValid &&
                                                        <span>
                                                            <img
                                                                className="ml-3 inline-block"
                                                                src={warningIcon}
                                                                width={20}
                                                                title={`The name of this document is not valid, it should starts with "${document.identifier}-"`}
                                                                alt="Warning icon"
                                                            />
                                                        </span>
                                                    }
                                                </td>
                                                <td className="py-3 px-1 border-t border-background-third align-middle">
                                                    <button className="rounded-full bg-background-third p-2 hover:bg-active"
                                                        title="Copy document sharing url"
                                                        onClick={() => this.onClickCopyDocumentUrl(document.identifier, document.url)}>
                                                        <img src={copyIcon} width={15} alt="Copy document url"/>
                                                    </button>
                                                    {state.copiedDocument === document.identifier &&
                                                        <span className="rounded-full bg-background-secondary px-3 py-1 ml-3 absolute animate__animated animate__slideInLeft">Document&apos;s sharing url copied!</span>
                                                    }
                                                </td>
                                                <td className="py-3 px-1 border-t border-background-third align-middle">
                                                    <button
                                                        className={`${(state.selectedDocumentId === document.identifier) ? 'bg-active' : 'bg-background-third'} rounded-full  p-2 hover:bg-active`}
                                                        onClick={() => this.onClickRelatedFiles(document.identifier, document.name)}>
                                                        <img src={searchFolderIcon} width={15} alt="Related files"/>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                }
                                {props.documents.length === 0 &&
                                    <div className="w-full">
                                        <p className="bg-active px-5 py-3 mt-6 rounded-md">No documents.</p>
                                    </div>
                                }
                            </div>
                        }
                        {!state.loading && state.relatedDocuments &&
                            <div className="bg-background-third py-10 px-10 mt-5">
                                <button className="text-active mb-3" onClick={this.handleClickBackRelatedDocuments}>&larr; Documents</button>
                                <DocumentsRelated relatedDocuments={state.relatedDocuments} selectedDocumentId={state.selectedDocumentId} />
                            </div>
                        }
                        {state.loading &&
                            <div className="bg-background-third py-10 px-10 mt-5">
                                <LoadingView size="small" />
                            </div>
                        }
                    </div>
                );
            }
        }
    }

    @autobind
    async onClickRelatedFiles(documentId) {
        this.setState(state => ({
            ...state,
            selectedDocumentId: documentId,
            relatedDocuments: null,
            loading: true,
        }));

        try {
            const response = await gapi.client.drive.files.list({
                pageSize: 1000,
                orderBy: 'modifiedTime desc',
                q: `name contains '${documentId}' and trashed=false`,
                fields: 'files(id,name,mimeType,webViewLink,parents)',
                supportsAllDrives: true,
                includeItemsFromAllDrives: true,
                corpora: 'allDrives'
            });

            const filesWithParentInfo = await this.getFilesWithParentInfo(response.result.files);

            console.debug(`Related documents - ${  documentId}`, filesWithParentInfo);

            this.setState(state => ({
                ...state,
                relatedDocuments: filesWithParentInfo,
                loading: false,
            }));
        } catch (err) {
            console.error(err.message);
        }
    }

    @autobind
    handleClickBackRelatedDocuments() {
        this.setState(state => ({
            ...state,
            selectedDocumentId: null,
            relatedDocuments: null,
        }));
    }

    async getFilesWithParentInfo(files) {
        for(let i=0; i < files.length; i++) {
            const file = files[i];

            const responseParentGet = await gapi.client.drive.files.get({
                'fileId': file.parents[0],
                'fields': 'id,name,webViewLink',
                supportsAllDrives: true,
            });

            file.parentName = responseParentGet.result.name;
            file.parentWebViewLink = responseParentGet.result.webViewLink;
        }

        return files;
    }

    @autobind
    onClickCopyDocumentUrl(documentId, documentUrl) {
        navigator.clipboard.writeText(documentUrl)
            .then(() => {
                this.setState(state => ({
                    ...state,
                    copiedDocument: documentId,
                }));

                setTimeout(() => this.setState(state => ({
                    ...state,
                    copiedDocument: null,
                })), 3000);

                return documentId;
            })
            .catch(error => {
                console.error(error);
                return false;
            });
    }
}

export { DocumentsEditable };
