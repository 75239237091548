import { model } from '@/store/simpler-redux';

const windowSize = model({
    name: 'windowSize',
    state: {
        W: globalThis.innerWidth,
        H: globalThis.innerHeight,
    },
    reducers: {
        update: () => {
            return {
                W: globalThis.innerWidth,
                H: globalThis.innerHeight,
            };
        },
    },
    actions: {
    },
});

export {windowSize};
